var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{staticClass:"form-row"},[_c('DetailOrderItemInputInventory',{ref:"dropdownInventory",staticClass:"col-12",attrs:{"label":"สินค้า","placeholder":"สินค้า...","select":"id","docType":_vm.docType,"templateType":_vm.templateType,"validations":[
        {text: 'required!', value: _vm.$v.formData.docConfigId.$dirty && _vm.$v.formData.docConfigId.$error}
      ]},on:{"change":function($event){_vm.selectedConfig = $event}},model:{value:(_vm.formData.docConfigId),callback:function ($$v) {_vm.$set(_vm.formData, "docConfigId", $$v)},expression:"formData.docConfigId"}}),_c('sgv-input-number',{staticClass:"col-6",attrs:{"label":"จำนวน","placeholder":"จำนวน","precision":2,"validations":[
        {text: 'required!', value: _vm.$v.formData.qty.$dirty && !_vm.$v.formData.qty.required},
        {text: 'จำนวนมากกว่า 0', value: _vm.$v.formData.qty.$dirty && !_vm.$v.formData.qty.minValue}
      ]},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.createOrder.apply(null, arguments)}},model:{value:(_vm.formData.qty),callback:function ($$v) {_vm.$set(_vm.formData, "qty", $$v)},expression:"formData.qty"}}),_c('sgv-input-text',{staticClass:"col-6",attrs:{"label":"stockId","placeholder":"stockId","validations":[
        {text: 'required!', value: _vm.$v.formData.stockId.$dirty && _vm.$v.formData.stockId.$error}
      ]},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.createOrder.apply(null, arguments)}},model:{value:(_vm.formData.stockId),callback:function ($$v) {_vm.$set(_vm.formData, "stockId", $$v)},expression:"formData.stockId"}}),_vm._l((_vm.formData.transferItems),function(transferItem,i){return [(transferItem.disabled)?_c('sgv-input-number',{key:transferItem.id,staticClass:"col-6",attrs:{"disabled":"","precision":2,"label":((transferItem.inventory.code) + "\n          (" + (transferItem.inventory.name) + ")\n          [จำนวน] * " + (transferItem.qty) + "\n          " + (transferItem.inventory.unit.name)),"value":transferItem.qty * Number(_vm.formData.qty || 1),"validations":[
          {text: 'required!', value: _vm.$v.formData.transferItems.$each[i].qty.$dirty && !_vm.$v.formData.transferItems.$each[i].qty.required},
          {text: 'จำนวนมากกว่า 0', value: _vm.$v.formData.transferItems.$each[i].qty.$dirty && !_vm.$v.formData.transferItems.$each[i].qty.minValue}
        ]}}):_c('sgv-input-number',{key:transferItem.id,staticClass:"col-6",attrs:{"precision":2,"label":((transferItem.inventory.code) + "\n          (" + (transferItem.inventory.name) + ")\n          [จำนวน]\n          " + (transferItem.inventory.unit.name)),"validations":[
          {text: 'required!', value: _vm.$v.formData.transferItems.$each[i].qty.$dirty && !_vm.$v.formData.transferItems.$each[i].qty.required},
          {text: 'จำนวนมากกว่า 0', value: _vm.$v.formData.transferItems.$each[i].qty.$dirty && !_vm.$v.formData.transferItems.$each[i].qty.minValue}
        ]},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.createOrder.apply(null, arguments)}},model:{value:(transferItem.qty),callback:function ($$v) {_vm.$set(transferItem, "qty", $$v)},expression:"transferItem.qty"}})]})],2),_c('div',{staticClass:"form-group"},[_c('button',{staticClass:"btn btn-success",on:{"click":_vm.createOrder}},[_vm._v(" เพิ่ม ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }