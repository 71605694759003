import gql from 'graphql-tag'


export const LIST_LABEL_DEVICE = (templateType) => gql`query LIST_LABEL_DEVICE ($docType: String!) {
  devices: listDoc${templateType}LabelDevice (docType: $docType) {
    id type code name
  }
}`

export const PRINT_LABEL = (templateType) => gql`mutation PRINT_LABEL ($docType: String!, $deviceId: Int!, $docId: Int!, $qty: Int!) {
  print: printDoc${templateType}Label (docType: $docType, deviceId: $deviceId, docId: $docId, qty: $qty)
}`
