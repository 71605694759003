<template>
  <b-modal
    v-model="show"
    hide-footer
    :title="title">
    <div class="form-row">
      <sgv-input-select
        class="col-12 col-sm-6"
        label="เครื่องพิมพ์"
        placeholder="เลือกเครื่องพิมพ์..."
        :options="devices"
        select="id"
        v-model="deviceId">
        <template slot-scope="option">
          {{option.name}}
        </template>
      </sgv-input-select>

      <div class="form-group col-12 col-sm-6">
        <label>
          จำนวน:
        </label>
        <div class="input-group">
          <input
            type="number"
            class="form-control"
            placeholder="จำนวน"
            v-model.number="qty"
            @keyup.enter="printLabel">
          <div class="input-group-append">
            <button class="btn btn-primary" type="button" @click="printLabel">
              <fa icon="print"></fa>
            </button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import {
  LIST_LABEL_DEVICE,
  PRINT_LABEL
} from './graph/label'

export default {
  mixins: [retainMixin],
  props: {
    title: {
      type: String,
      required: true
    },
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      rKey: `Doc${this.$form.capitalize(this.docType)}DetailPrint`,
      rFields: ['deviceId'],
      deviceId: null,
      qty: 1,
      devices: [],
      show: false
    }
  },
  apollo: {
    devices: {
      query () {
        return LIST_LABEL_DEVICE(this.templateType)
      },
      variables () {
        return {docType: this.docType}
      },
      fetchPolicy: 'no-cache'
    }
  },
  methods: {
    printLabel () {
      if (this.qty > 0) {
        if (!this.deviceId) {
          this.$toasted.global.error("โปรดเลือกเครื่องพิมพ์")
          return
        } else {
          this.$apollo.mutate({
            mutation: PRINT_LABEL(this.templateType),
            variables: {
              docType: this.docType,
              deviceId: this.deviceId,
              docId: this.docId,
              qty: this.qty
            }
          })
          .then(() => {
            this.$toasted.global.success('ส่งข้อมูลไปยังเครื่องพิมพ์...')
          })
          .catch(this.$toasted.global.error)
        }
      } else {
        this.$toasted.global.error("ระบุจำนวนไม่ถูกต้อง")
      }
    },
    toggle () {
      this.show = !this.show
    }
  },
  watch: {
    deviceId () {
      this.setRetaining()
    }
  }
}
</script>

<style lang="css">
</style>
